import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/global/ContactForm"
import SocialRoundButtons from "../components/global/SocialRoundButtons"
import { MdEmail } from "react-icons/md"
import { FaPhoneAlt } from "react-icons/fa"
import { IoLocationSharp } from "react-icons/io5"
const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contact me" />
      <div className="container mx-auto py-24">
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 mx-1 p-5 justify-center">
            <div className="pl-0 md:pl-12 font-semibold text-center md:text-left">
              <h2 className=" text-2xl mb-5">Contact Information</h2>
              <ul className="font-semibold pl-1">
                <li className=" gap-1 pt-3">
                  <div className="inline-block">
                    <div className="flex items-center gap-2">
                      <MdEmail />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="mailto:hello@faisalantu.com"
                      >
                        hello@faisalantu.com
                      </a>
                    </div>
                  </div>
                </li>
                <li className=" gap-1 pt-3">
                  <div className="inline-block">
                    <div className="flex items-center gap-2">
                      <FaPhoneAlt />
                      <a href="tek:+880 1776-197074">+880 1776-197074</a>
                    </div>
                  </div>
                </li>
                <li className=" gap-1 pt-3">
                  <div className="inline-block">
                    <div className="flex items-center gap-2">
                      <IoLocationSharp />
                      <span>Dhaka, Bangladesh</span>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="mt-2 md:mt-5">
                <SocialRoundButtons />
              </div>
            </div>
          </div>
          <div className="flex-1 mx-1">
            <ContactForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
